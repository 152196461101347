<template>
  <div>
    <default-app-bar>
      <template v-slot:title>
        <div class="text-h3 mr-2">Reporte de Intereses Generados</div>
      </template>

      <!--template v-slot:actions>
              <router-link  to="/admin/solicitudes/list-desembolsado"  class="mr-2 white-text" >
                Volver
              </router-link>
          
         </template-->
    </default-app-bar>
    <v-card-text v-if="form">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="12" class="form-col">
            <ng-integer
              v-bind:form="form"
              :size="12"
              v-bind:options="config.id"
            ></ng-integer>
          </v-col>

          <v-col cols="12" sm="12" class="form-col">
            <ng-chips
              v-bind:form="form"
              :size="12"
              v-bind:options="config.bus_person_cliente_id"
            ></ng-chips>
          </v-col>
          <v-col cols="12" sm="12" class="form-col">
            <ng-date-range
              v-bind:form="form"
              :size="12"
              v-bind:options="config.fecha_pago"
            ></ng-date-range>
          </v-col>
          <!--v-col cols="12" sm="6" class="form-col"  > 
                <ng-select-multi  v-bind:form="form" size="12"  v-bind:options="config.bus_producto_id"></ng-select-multi>
            </v-col-->

          <v-col cols="12" sm="12" class="text-right">
            <v-btn v-on:click="search()" block color="primary">Buscar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <div v-if="tmpl" :key="renderKey" class="pa-4">
      <v-btn v-on:click="excel()" v-if="info" color="primary" small>Descargar</v-btn>
      <!--div class="text-right">
              <v-btn size="small" icon v-on:click="excel()"><v-icon>mdi-file-excel-box</v-icon></v-btn>
            </div-->
      <component :is="getComponent()" :info="info"></component>
    </div>
  </div>
</template>

<script>
import { NgForm, handler } from "@/components/gui/NgForm";
import {
  VCard,
  VCardText,
  VCardTitle,
  VIcon,
  VContainer,
  VRow,
  VCol,
  VBtn,
  VDialog,
  VDataTable,
  VSimpleTable,
  VCombobox,
  VSelect,
  VPagination,
  VDivider,
  VList,
  VListItem,
  VListItemTitle,
  VListItemContent,
  VListItemSubtitle,
  VListItemAction,
  VMenu,
} from "vuetify/lib";

export default {
  name: "Pagos",
  data() {
    return {
      form: null, //{ bus_person_cliente_id: null, fecha_desembolso: { from: null, to: null } },
      config: {
        id: { label: "Crédito", name: "id" },
        bus_person_cliente_id: {
          label: "Cliente",
          dense: false,
          name: "bus_person_cliente_id",
          url: "api/v1/admin/person/search",
        },
        fecha_pago: { label: "Fecha", name: "fecha_pago" },
        bus_producto_id: {
          label: "Producto",
          name: "bus_producto_id",
          url: "api/default/bus_producto/search",
        },
        /*bus_medio_pago_id: { label: 'Medio de desembolso', name: 'bus_medio_pago_id', url: 'api/default/bus_medio_pago/search' },
            bus_convenio_admin_cartera_id: { label: 'Origen de Fondos', name: 'bus_convenio_admin_cartera_id', url: 'api/admin/person/search-inver' },*/
      },
      renderKey: 1,
      info: null,
      tmpl: `
            <v-list v-if="info">
             <template v-for="item in info.results">
              <v-list-item>
                <v-list-item-content class="item-credito">
                    <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
                    <span class="b1"> Crédito Nro: {{item.bus_solicitud_id}} </span> 
                    <span class="b1"> {{$filters.date(item.fecha_pago)}} </span> 
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
                      <span class="b1">Cliente:</span><span> {{item.cliente}}</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
                      <span class="b1">Intereses:</span><span> {{$filters.currency(item.total_intereses)}}</span>
                    </div>
                    <!--template v-for="c in info.conceptos">
                      <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
                        <span class="b1">{{c.name}}</span><span> {{$filters.currency(item['ctotal-'+c.id])}}</span>
                      </div>
                      <div style="display: flex;justify-content: space-between;margin-bottom:2px;" v-if="item['cimp-'+c.id]">
                        <span class="b1">IVA</span><span> {{$filters.currency(item['cimp-'+c.id])}}</span>
                      </div>
                    </template>

                    <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
                        <span class="b1">Valor Desembolsado</span><span> {{$filters.currency(item.total_a_desembolsar)}}</span>
                      </div-->
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
            </v-list>
          `,
    };
  },
  mounted() {
    //console.log(this.pageConfg)
    const form = new NgForm(this.config, this);
    this.form = new Proxy(form, handler);
    //this.loadItem();
  },
  methods: {
    getFormValue() {
      const value = this.form.value;
      //value['_conv_per_id'] = this.user.personId;
      return value;
    },

    toogleDrawer() {
      this.$store.commit("toggle");
    },
    search() {
      this.$http
        .queryReport("report/html/ingresos-generados", {
          data: this.getFormValue(),
        })
        .then((res) => {
          //console.log(res)
          this.info = res.data.info;
          //this.tmpl = res.data.html;
          this.renderKey++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    excel() {
      let value = this.getFormValue();
      let user = JSON.parse(localStorage.getItem("auth"));
      value.u = user.personId;
      value.g = user.grupo.id;
      const q = this.serialize_query(value);
      window.open(
        "https://reportes.serprogreso.com/" +
          "report/excel/ingresos-generados?" +
          q,
        "_blank"
      );
    },
    serialize_query(params) {

      const value = {};

      for (const i in params) {
        if (Array.isArray(params[i])) {
          value[i] = params[i].map((item) => {
            if (item.id) {
              return { id: item.id };
            }
            return item;
          });
        } else {
          if (params[i] && params[i].id) {
            value[i] = { id: params[i].id };
          } else {
            value[i] = params[i];
          }
        }
      }

      return "params=" + JSON.stringify(value);
    },
    getComponent() {
      const tmpl = this.tmpl;
      return {
        data() {
          return {
            data: null,
          };
        },
        props: ["info"],
        components: {
          VCard,
          VCardText,
          VCardTitle,
          VIcon,
          VContainer,
          VRow,
          VCol,
          VBtn,
          VDialog,
          VDataTable,
          VSimpleTable,
          VCombobox,
          VSelect,
          VPagination,
          VDivider,
          VList,
          VListItem,
          VListItemTitle,
          VListItemContent,
          VListItemSubtitle,
          VListItemAction,
          VMenu,
        },
        template: tmpl,
        mounted() {
          this.data = this.info;
        },
      };
    },
  },
};
</script>
